import styled from 'styled-components'
import Anteater from "./components/Anteater"

const App = ({ className }) => {
    return (
        <div className={className}>
            <Anteater />
        </div>
    )
}

export default styled(App)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
`
